

















































import { Vue, Component, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import BulletOptions from '@/components/BulletOptions/BulletOptions.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Footer from '@/components/Footer/Footer.vue';

import SimulatorResultAccordion from '../../components/SimulatorResultAccordion/SimulatorResultAccordion.vue';
import SimulatorResultLoading from './SimulatorResultLoading.vue';

import SimulationService from '@/services/Simulation/SimulationService';

import { Vacancy, GroupVacancy } from '../../components/interface/ISimulatorAccordion';
import { CardScore } from '../../components/interface/ISisuScore';
import { TypeVacancy } from './interface/ISimulatorResult';
import { Course, State } from '../../components/SelectChoice/interface/ISelectOptions';

import {
  GROUP_NAME_APPROVED,
  GROUP_NAME_ALMOST_APPROVED,
  GROUP_NAME_SURPLUS
} from '../../components/constants/constants';

import { LIST_STATE } from '../../components/SelectChoice/constants/SelectsOptions';

const MAX_DIFFERENCE_GROUP_ALMOST_APPROVED = 20;

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    Footer,
    SimulatorResultAccordion,
    BulletOptions,
    SimulatorResultLoading,
    FeedbackUser
  }
})
export default class SimulatorResult extends Vue {
  private loading = {
    isVacancy: true,
    isTypeVacancy: true
  };

  private simulatorResult: GroupVacancy | {} = {};
  private simulatorStore: any = {};

  private listVacancy: Array<Vacancy> = [];
  private listTypesVacancy: Array<TypeVacancy> = [];

  private SimulationService = new SimulationService();

  created() {
    this.getChoiceSimulatorStore();
    this.getListTypesVacancy();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isLoading() {
    return this.loading.isVacancy || this.loading.isTypeVacancy;
  }

  @Watch('simulatorStore', {
    deep: true
  })
  @Watch('listTypesVacancy', {
    deep: true
  })
  async getListVacancy() {
    try {
      if (!Object.keys(this.simulatorStore) || !this.listTypesVacancy.length) return;

      this.loading.isVacancy = true;
      const listIdsCoursesChoice = this.listIdCourseChoiceSimulator();
      this.listVacancy = await this.SimulationService.getListCourseSimulator(listIdsCoursesChoice);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a lista de cursos.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.loading.isVacancy = false;
    }
  }

  async getListTypesVacancy() {
    try {
      this.loading.isTypeVacancy = true;
      this.listTypesVacancy = await this.SimulationService.getListQuotes();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a lista de vagas.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.loading.isTypeVacancy = false;
    }
  }

  @Watch('listVacancy', {
    deep: true
  })
  setSimulatorResult() {
    const listIdsTypes = this.findListIdTypesVacancyChoice();
    let listVacancy = this.filterVacancyForTypesVacancy(listIdsTypes);
    listVacancy = this.filterVacancyForRegion(listVacancy);
    listVacancy = this.filterVacancyForState(listVacancy);

    this.simulatorResult = this.separateListVacancyForAverage(listVacancy);
  }

  findListIdTypesVacancyChoice(): Array<number> {
    return this.listTypesVacancy.reduce<Array<number>>(
      (prevListTypeVacancy: Array<number>, typeVacancy: TypeVacancy) => {
        if (this.simulatorStore.vacancy.value === typeVacancy.slug) return [...prevListTypeVacancy, typeVacancy.id];
        return prevListTypeVacancy;
      },
      []
    );
  }

  filterVacancyForTypesVacancy(listIdTypesVacancy: Array<number>): Array<Vacancy> {
    return this.listVacancy.filter((vacancy: Vacancy) => listIdTypesVacancy.includes(vacancy.quoteId));
  }

  filterVacancyForRegion(listVacancy: Array<Vacancy>) {
    const regionSelect = this.simulatorStore?.region?.value;
    if (!regionSelect) return listVacancy;

    const listStates = LIST_STATE.filter(
      (state: State) => state.region?.value === regionSelect
    ).map((state: State) => state.value);

    return listVacancy.filter((vacancy: Vacancy) => listStates.includes(vacancy.state));
  }

  filterVacancyForState(listVacancy: Array<Vacancy>) {
    const stateSelect = this.simulatorStore?.state?.value;
    if (!stateSelect) return listVacancy;

    return listVacancy.filter((vacancy: Vacancy) => vacancy.state === stateSelect);
  }

  listIdCourseChoiceSimulator() {
    const optionsStore = this.simulatorStore;

    return optionsStore?.courses.map((course: Course) => course.value);
  }

  separateListVacancyForAverage(listVacancy: Array<Vacancy>) {
    if (!listVacancy.length) return {};

    return listVacancy.reduce<GroupVacancy | any>(
      (prevVacancy: GroupVacancy, nextVacancy: Vacancy) => {
        const userScore = this.averageWeightedScore(nextVacancy);
        const differenceBetweenMinScoreAndUserScore = Number(nextVacancy.minScore) - userScore;

        if (userScore >= Number(nextVacancy.minScore)) {
          return {
            ...prevVacancy,
            [GROUP_NAME_APPROVED]: [...prevVacancy.Aprovado, nextVacancy]
          };
        }

        if (differenceBetweenMinScoreAndUserScore <= MAX_DIFFERENCE_GROUP_ALMOST_APPROVED) {
          return {
            ...prevVacancy,
            [GROUP_NAME_ALMOST_APPROVED]: [...prevVacancy.Suplente, nextVacancy]
          };
        }

        return {
          ...prevVacancy,
          [GROUP_NAME_SURPLUS]: [...prevVacancy.Excedente, nextVacancy]
        };
      },
      { [GROUP_NAME_APPROVED]: [], [GROUP_NAME_ALMOST_APPROVED]: [], [GROUP_NAME_SURPLUS]: [] }
    );
  }

  averageWeightedScore(vacancy: any) {
    const totalWeight = Object.keys(vacancy.weight).reduce(
      (prevWeight: number, nextWeight: string) => prevWeight + Number(vacancy.weight[nextWeight]),
      0
    );
    const average = this.simulatorStore.score.reduce(
      (prevScore: number, nextScore: CardScore) => prevScore + Number(nextScore.score) * Number(vacancy.weight[nextScore.slug]),
      0
    );

    return average / totalWeight;
  }

  getChoiceSimulatorStore() {
    this.simulatorStore = this.$store.getters.optionsSimulator;
    this.redirectSimulator();
  }

  redirectSimulator() {
    if (!Object.keys(this.simulatorStore).length) this.$router.push({ name: 'EnemSimulator', params: { id: this.$route.params.id } });
  }

  goToPageSimulator() {
    const { id } = this.$route.params;
    this.$router.push({
      name: 'EnemSimulator',
      params: {
        id
      }
    });
  }

  goToDashboard() {
    this.$router.push({
      name: 'Dashboard'
    });
  }

  onDownload() {
    setTimeout(() => {
      window.print();
    }, 300);
  }

  onPrint() {
    this.onDownload();
  }

  setBreadCrumbs() {
    const { id } = this.$route.params;
    const routeSimulator = id
      ? `/simulador/${id}/resultado/simulador_enem`
      : '/simulador/resultado/simulador_enem';

    this.$breadcrumb.set([
      { title: 'Simulados', to: '/simulados' },
      { title: 'Simulador', to: routeSimulator },
      { title: 'Resultado ', to: null }
    ]);
  }
}
