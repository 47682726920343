const FIRST_COURSE_ID = 0;
const SECOND_COURSE_ID = 1;
const THIRD_COURSE_ID = 2;

const LIST_REGION = [
  {
    value: 'N',
    title: 'Norte'
  },
  {
    value: 'NE',
    title: 'Nordeste'
  },
  {
    value: 'SE',
    title: 'Sudeste'
  },
  {
    value: 'S',
    title: 'Sul'
  },
  {
    value: 'CO',
    title: 'Centro-Oeste'
  }
];

const LIST_STATE = [
  {
    value: 'RO',
    title: 'Rondônia',
    region: {
      value: 'N',
      title: 'Norte'
    }
  },
  {
    value: 'AC',
    title: 'Acre',
    region: {
      value: 'N',
      title: 'Norte'
    }
  },
  {
    value: 'AM',
    title: 'Amazonas',
    region: {
      value: 'N',
      title: 'Norte'
    }
  },
  {
    value: 'RR',
    title: 'Roraima',
    region: {
      value: 'N',
      title: 'Norte'
    }
  },
  {
    value: 'PA',
    title: 'Pará',
    region: {
      value: 'N',
      title: 'Norte'
    }
  },
  {
    value: 'AP',
    title: 'Amapá',
    region: {
      value: 'N',
      title: 'Norte'
    }
  },
  {
    value: 'TO',
    title: 'Tocantins',
    region: {
      value: 'N',
      title: 'Norte'
    }
  },
  {
    value: 'MA',
    title: 'Maranhão',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'PI',
    title: 'Piauí',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'CE',
    title: 'Ceará',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'RN',
    title: 'Rio Grande do Norte',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'PB',
    title: 'Paraíba',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'PE',
    title: 'Pernambuco',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'AL',
    title: 'Alagoas',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'SE',
    title: 'Sergipe',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'BA',
    title: 'Bahia',
    region: {
      value: 'NE',
      title: 'Nordeste'
    }
  },
  {
    value: 'MG',
    title: 'Minas Gerais',
    region: {
      value: 'SE',
      title: 'Sudeste'
    }
  },
  {
    value: 'ES',
    title: 'Espírito Santo',
    region: {
      value: 'SE',
      title: 'Sudeste'
    }
  },
  {
    value: 'RJ',
    title: 'Rio de Janeiro',
    region: {
      value: 'SE',
      title: 'Sudeste'
    }
  },
  {
    value: 'SP',
    title: 'São Paulo',
    region: {
      value: 'SE',
      title: 'Sudeste'
    }
  },
  {
    value: 'PR',
    title: 'Paraná',
    region: {
      value: 'S',
      title: 'Sul'
    }
  },
  {
    value: 'SC',
    title: 'Santa Catarina',
    region: {
      value: 'S',
      title: 'Sul'
    }
  },
  {
    value: 'RS',
    title: 'Rio Grande do Sul',
    region: {
      value: 'S',
      title: 'Sul'
    }
  },
  {
    value: 'MS',
    title: 'Mato Grosso do Sul',
    region: {
      value: 'CO',
      title: 'Centro-Oeste'
    }
  },
  {
    value: 'MT',
    title: 'Mato Grosso',
    region: {
      value: 'CO',
      title: 'Centro-Oeste'
    }
  },
  {
    value: 'GO',
    title: 'Goiás',
    region: {
      value: 'CO',
      title: 'Centro-Oeste'
    }
  },
  {
    value: 'DF',
    title: 'Distrito Federal',
    region: {
      value: 'CO',
      title: 'Centro-Oeste'
    }
  }
];

const LIST_VACANCY = [
  {
    id: 1,
    title: 'Vagas de ampla concorrência',
    value: 'ampla concorrencia'
  },
  {
    id: 2,
    title: 'Vagas para escolas públicas',
    value: 'publica'
  },
  {
    id: 3,
    title: 'Cotas para ações afirmativas',
    value: 'cotas'
  }
];

const MESSAGE_REQUIRED_COURSE = 'O curso 1 é obrigatório!';
const MESSAGE_REQUIRED_TYPE_VACANCY = 'Campo obrigatório!';

export {
  FIRST_COURSE_ID,
  SECOND_COURSE_ID,
  THIRD_COURSE_ID,
  LIST_REGION,
  LIST_STATE,
  LIST_VACANCY,
  MESSAGE_REQUIRED_COURSE,
  MESSAGE_REQUIRED_TYPE_VACANCY
};
