
























import { Component, Vue } from 'vue-property-decorator';

import iBullets from '@/assets/icons/Bullets.vue';
import Settings from '@/assets/icons/Settings.vue';
import DownloadTab from '@/assets/icons/DownloadTab.vue';
import Print from '@/assets/icons/Print.vue';

@Component({
  components: {
    iBullets,
    Settings,
    DownloadTab,
    Print
  }
})
export default class BulletOptions extends Vue {
  private isActiveBullet = false;

  download() {
    this.showBulletOption();
    this.$emit('download');
  }

  print() {
    this.showBulletOption();
    this.$emit('print');
  }

  showBulletOption() {
    this.isActiveBullet = !this.isActiveBullet;
  }

  disableBulletOptions() {
    this.isActiveBullet = false;
  }
}
