




















import { Vue, Prop, Component } from 'vue-property-decorator';

import { Weight } from '../interface/ISimulatorAccordion';

@Component({})
export default class CourseWeight extends Vue {
  @Prop({ required: true }) weight!: Weight;
}
