
































import { Component, Vue, Prop } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ExLoading
  }
})
export default class AccordionLoading extends Vue {
  @Prop({ default: true }) showLoadingTitle!: boolean;
  @Prop({ default: 'mb-5' }) classesTitle!: string;

  private quantityLoading = 4;
}
